<template>
  <div>
    <div class="wrap pb-16">
      <page-info
        :name="$t('global.wallets')"
        :description="$t('pages.votingWallets')"
      />

      <buttons-menu />

      <div class="my-3">
        <div>
          <v-card
            class="mt-3"
            v-for="(percent, index) in participantsAdresses"
            :key="index + '_' + percent"
            elevation="2"
          >
            <v-card-text>
              <div class="d-flex align-center">
                <Avatar
                  :name="participantsAdresses[index].toString()"
                  :size="40"
                />
                <div class="ml-5">
                  {{ $shortAddress(participantsAdresses[index], windowWidth) }}
                </div>
                <div
                  class="ml-5"
                  v-if="web3.network == 1 && participantsVote[index]"
                >
                  <v-chip color="primary">{{ $t("chips.voter") }}</v-chip>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonsMenu from "../components/buttonsMenu";
import PageInfo from "../components/pageInfo";
import Avatar from "../components/avatar";
import windowWidth from "@/mixins/windowWidth";

export default {
  mixins: [windowWidth],
  components: {
    PageInfo,
    ButtonsMenu,
    Avatar,
  },

  data() {
    return {
      participantsAdresses: [],
      participantsVote: [],
    };
  },

  watch: {
    blockchainConnected(data) {
      this.participantsAdresses = [];
      if (data) {
        this.getParticipants();
      }
    },
  },

  computed: {
    blockchainConnected() {
      return this.$store.state.web3.active;
    },
    web3() {
      return this.$store.state.web3;
    },
  },

  methods: {
    async getParticipants() {
      const info = await this.$web3Call("percentagePayouts");
      if (this.web3.network == 1) this.participantsVote = info._isVoter;
      this.participantsAdresses = info.participantsAdresses;
    },
  },

  mounted() {
    if (this.blockchainConnected) {
      this.getParticipants();
    }
  },
};
</script>
